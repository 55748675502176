import React from 'react'
import Layout from 'layouts/fr'
import styled from 'styled-components'
import design from 'design'
import { Title, Text, Row, Div, Box, Button, Table } from 'components'
import { TryOrSchedule, Calculator } from 'containers/fr'

export default class Pricing extends React.Component {
  state = {
    calculator: false,
  }

  calculator = e => {
    this.setState({ calculator: true })
  }

  render() {
    return (
      <Layout p={5}>
        <Div pt={[6,8]} px={[0,6]} textAlign={['center', 'left']}>
          <Title mb={5} fontSize={[7,9]}>Stairlin est gratuit pour les petits volumes</Title>
          <Text tag={'h2'} mb={7} fontSize={[6,8]}>Ne payez pas un seul centime avant d’être convaincu. Sans clauses cachées.</Text>

          <Div display={['block','flex']} justifyContent={'space-between'}>
            <Div>
              <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
                <li><Text mb={[3,5]} fontSize={[3,7]}>• Pas de frais initiaux</Text></li>
                <li><Text mb={[3,5]} fontSize={[3,7]}>• Facturation à l’utilisation</Text></li>
                <li><Text mb={[3,5]} fontSize={[3,7]}>• Aucun frais de résiliation </Text></li>
              </ul>
              <Div display={['block','none']}>
                <TryOrSchedule onClick={this.calculator} linkText={`Estimation de vos coûts`} justCTA={true} />
              </Div>
            </Div>
            <Div mx={[-36,0]} display={[this.state.calculator ? 'block' : 'none','block']}>
              <Box bg={'white'} p={[3,5]}>
                <Calculator />
              </Box>
            </Div>
          </Div>

          <Div display={['block','flex']} mt={[7,8]}>
            <Div flex={1} mb={[6,7]} mr={[0,120]} textAlign={['center','left']}>
              <Title mb={4} fontSize={[3,6]}>Pourquoi sommes-nous différents ?</Title>
              <Text fontSize={[2,3]}>Contrairement aux prestataires traditionnels, nous ne comptons pas sur des contrats à long terme pour fidéliser nos clients. Ces derniers ont de nombreuses raisons de continuer à utiliser Stairlin, notamment nos tarifs compétitifs, notre simplicité, nos solutions innovantes et nos principes et pratiques de sécurité stricts.  Nous sommes persuadés de pouvoir vous garder à nos côtés sans contrat.</Text>
            </Div>
            <Div flex={1} mb={[6,7]} mr={[0,120]} textAlign={['center','left']}>
              <Title mb={4} fontSize={[3,6]}>Gratuit pour les petits volumes</Title>
              <Text fontSize={[2,3]}>Nous savons à quel point il est difficile de démarrer un nouveau business. Il peut y avoir beaucoup de couts initiaux associés à une incertitude. C’est la raison pour laquelle nous avons créé un forfait gratuit, qui vous permet de vous concentrer sur la croissance et le développement de votre établissement tout en offrant l’intégralité des fonctionnalités de Stairlin à vos clients et à vous-même.</Text>
            </Div>
          </Div>
          <Div display={['block','flex']}>
            <Div flex={1} mb={[6,7]} mr={[0,120]} textAlign={['center','left']}>
              <Title mb={4} fontSize={[3,6]}>Services illimités sans clauses cachées</Title>
              <Text fontSize={[2,3]}>Contrairement aux prestataires de services traditionnels qui offrent des “services illimités” avec des clauses cachées en bas de page, Stairlin vous donne accès à un nombre réellement illimité de réservations, rappels, factures, e-mails, SMS, etc. Quels que soient vos besoins, Stairlin s’adapte à vous.</Text>
            </Div>
            <Div flex={1} mb={[6,7]} mr={[0,120]} textAlign={['center','left']}>
              <Title mb={4} fontSize={[3,6]}>Utilisez plus et payez moins</Title>
              <Text fontSize={[2,3]}>Avec Stairlin, vous pouvez profiter de remises sur le volume et réaliser des économies importantes à mesure que votre consommation augmente. La tarification est progressive pour les services, tels que les notifications. Cela signifie que plus vous envoyez de notifications, moins vous payez par e-mail / SMS. Les établissements à haut volume bénéficient de réductions allant jusqu’à 80% sur notre prix de base grâce à notre tarification d’utilisation soutenue.</Text>
            </Div>
          </Div>

          <Div mt={[5,6]}>
            <Title mb={4} fontSize={[6,8]}>Liste de prix</Title>
            <Text fontSize={[3,5]}>Vous pouvez consulter ci-dessous la tarification pour chaque produit Stairlin. Les produits en phase de développement « alpha » ou « bêta » n’ont pas forcément de charges associées dans leur phase de lancement actuel. Ceci est susceptible à modifications.</Text>
          </Div>

          <Div tag={'article'}>
            <Div mt={[6,8]}>
              <Title mb={4} fontSize={[3,7]}>Réservations</Title>
              <Text fontSize={[2,5]}>Les frais de réservation sont basés sur le nombre de réservations effectuées.</Text>
            </Div>
            <Div mt={[6,7]} mb={6}>
              <Div display={['block','flex']}>
                <Box bg={'white'} height={['auto','fit-content']} m={0} p={[2,4]} display={'inline-flex'}>
                  <Table width={['auto',547]}>
                    <Table.Tr>
                      <Table.Th fontSize={3} p={2}>Niveau</Table.Th>
                      <Table.Th fontSize={3} p={2}>Réservations</Table.Th>
                      <Table.Th fontSize={3} p={2}>Prix par réservation (CHF)</Table.Th>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Gratuit</Table.Td>
                      <Table.Td fontSize={3} p={2}>0 - 10</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.00</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Atto</Table.Td>
                      <Table.Td fontSize={3} p={2}>11 - 25</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.50</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Femto</Table.Td>
                      <Table.Td fontSize={3} p={2}>26 - 50</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.33</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Pico</Table.Td>
                      <Table.Td fontSize={3} p={2}>51 - 100</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.22</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Nano</Table.Td>
                      <Table.Td fontSize={3} p={2}>101 - 125</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.14</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Micro</Table.Td>
                      <Table.Td fontSize={3} p={2}>126+</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.09</Table.Td>
                    </Table.Tr>
                  </Table>
                </Box>
                <Div ml={6} display={['none','block']}>
                  <Text mb={4} fontSize={3}>Exemples de tarification:<br/>
                    <ol>
                      <li><Text mb={4} fontSize={3}>Si vous faites 10 réservations par mois, le cout est de CHF 0.– (Les 10 premières réservations du mois sont gratuites.)</Text></li>
                      <li><Text mb={4} fontSize={3}>Si vous avez effectué 20 réservations et annulé 10 réservations par mois, le cout est de 0 CHF. (Vos 10 premières réservations par mois sont gratuites. Les réservations annulées sont déduites.)</Text></li>
                      <li><Text mb={4} fontSize={3}>Si vous effectuez 25 réservations par mois, le cout est de 7,50 CHF. (Vos 10 premières réservations par mois sont gratuites. Le cout des réservations restantes est calculé à 15 * CHF 0.50 = CHF 7.50.)</Text></li>
                      <li><Text mb={4} fontSize={3}>Si vous effectuez 100 réservations par mois, le cout est de CHF 26.75. (Vos 10 premières réservations par mois sont gratuites. Le cout des réservations restantes correspond à 15 * CHF 0.50 = CHF 7.50 + 25 * CHF 0.33 = CHF 8.25 + 50 * CHF 0.22 = CHF 11.00 → CHF 26.75.)</Text></li>
                    </ol>
                  </Text>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div tag={'article'}>
            <Div mt={[6,8]}>
              <Title mb={4} fontSize={[3,7]}>Notifications</Title>
              <Text fontSize={[2,5]}>Les frais de notification sont basés sur le nombre de notifications envoyées, y compris les notifications destinées à vos clients et à vous-même. Nous envoyons des notifications par e-mail, SMS et WhatsApp.</Text>
            </Div>
            <Div mt={[6,7]} mb={6}>
              <Div display={['block','flex']}>
                <Box bg={'white'} height={['auto','fit-content']} m={0} p={[2,4]} display={'inline-flex'}>
                  <Table width={['auto',547]}>
                    <Table.Tr>
                      <Table.Th fontSize={3} p={2}>Niveau</Table.Th>
                      <Table.Th fontSize={3} p={2}>Notifications</Table.Th>
                      <Table.Th fontSize={3} p={2}>Prix par notification (CHF)</Table.Th>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Gratuit</Table.Td>
                      <Table.Td fontSize={3} p={2}>0 - 10</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.00</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Atto</Table.Td>
                      <Table.Td fontSize={3} p={2}>36 - 88</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.18</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Femto</Table.Td>
                      <Table.Td fontSize={3} p={2}>89 - 175</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.14</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Pico</Table.Td>
                      <Table.Td fontSize={3} p={2}>176 - 350</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.10</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Nano</Table.Td>
                      <Table.Td fontSize={3} p={2}>351 - 438</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.07</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Micro</Table.Td>
                      <Table.Td fontSize={3} p={2}>439+</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.05</Table.Td>
                    </Table.Tr>
                  </Table>
                </Box>
                <Div ml={6} display={['none','block']}>
                  <Text mb={4} fontSize={3}>Exemples de tarification:<br/>
                    <ol>
                      <li><Text mb={4} fontSize={3}>Si vous avez envoyé 35 notifications par mois (par exemple 10 courriels et 25 SMS), votre cout est de 0 CHF. (Vos 35 premières notifications par mois sont gratuites.)</Text></li>
                      <li><Text mb={4} fontSize={3}>Si vous avez envoyé 100 notifications par mois, votre cout est de 14.72 CHF. (Vos 35 premières notifications par mois sont gratuites. Le montant des factures restantes est calculé comme suit: 53 * CHF 0.18 = CHF 9.54 + 12 * CHF 0.14 = CHF 1.68 → CHF 11.22)</Text></li>
                    </ol>
                  </Text>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div tag={'article'}>
            <Div mt={[6,8]}>
              <Title mb={4} fontSize={[3,7]}>Factures</Title>
              <Text fontSize={[2,5]}>Les frais de facturation sont basés sur le nombre de factures établies. Les factures annulées et les factures passées aux pertes et profits sont déduites.</Text>
            </Div>
            <Div mt={[6,7]} mb={6}>
              <Div display={['block','flex']}>
                <Box bg={'white'} height={['auto','fit-content']} m={0} p={[2,4]} display={'inline-flex'}>
                  <Table width={['auto',547]}>
                    <Table.Tr>
                      <Table.Th fontSize={3} p={2}>Niveau</Table.Th>
                      <Table.Th fontSize={3} p={2}>Factures</Table.Th>
                      <Table.Th fontSize={3} p={2}>Prix par facture (CHF)</Table.Th>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Gratuit</Table.Td>
                      <Table.Td fontSize={3} p={2}>0 - 10</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.00</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Atto</Table.Td>
                      <Table.Td fontSize={3} p={2}>11 - 25</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.50</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Femto</Table.Td>
                      <Table.Td fontSize={3} p={2}>26 - 40</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.30</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Pico</Table.Td>
                      <Table.Td fontSize={3} p={2}>41 - 80</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.18</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Nano</Table.Td>
                      <Table.Td fontSize={3} p={2}>81 - 100</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.11</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Micro</Table.Td>
                      <Table.Td fontSize={3} p={2}>101+</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.06</Table.Td>
                    </Table.Tr>
                  </Table>
                </Box>
                <Div ml={6} display={['none','block']}>
                  <Text mb={4} fontSize={3}>Exemples de tarification:<br/>
                    <ol>
                      <li><Text mb={4} fontSize={3}>Si vous avez envoyé 10 factures par mois, votre cout est de CHF 0.– (Les 10 premières factures du mois sont gratuites.)</Text></li>
                      <li><Text mb={4} fontSize={3}>Si vous avez envoyé 20 factures, annulé 5 factures et passé aux pertes et profits 5 autres factures par mois, votre cout est de 0 CHF. (Vos 10 premières factures par mois sont gratuites. Les factures annulées et celles passées aux pertes et profits sont déduites.)</Text></li>
                      <li><Text mb={4} fontSize={3}>Si vous avez envoyé 25 factures par mois, votre cout est de 7,50 CHF. (Vos 10 premières factures par mois sont gratuites. Le cout des factures restantes est calculé comme suit: 15 * 0,50 CHF = 7,50 CHF.)</Text></li>
                      <li><Text mb={4} fontSize={3}>Si vous envoyez 100 factures par mois, votre cout est de CHF 24.00. (Vos 10 premières factures par mois sont gratuites. Le cout des factures restantes est calculé sur la base de 15 * CHF 0.50 = CHF 7.50 + 15 * CHF 0.30 = CHF 4.50 + 40 * 0.18 = CHF 7.20 + 20 * 0.11 = CHF 2.2 → CHF 21.40.)</Text></li>
                    </ol>
                  </Text>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div mt={[8, 120]}>
            <TryOrSchedule />
          </Div>
        </Div>
      </Layout>
    )
  }
}
